<template>
  <modal name="jdy-dialog"
         classes="transparent-bg"
         height="auto"
         :adaptive="true"
         @before-open="beforeOpened"
         @before-close="beforeClosed">
    <div class="modal-dialog modalWrap">
      <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header">
          <div class="modal-title"> {{ params.title || '訊息提示' }} </div>
          <button type="button"
                  class="closeBtn"
                  data-dismiss="modal"
                  @click="closeDialog"></button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          {{ params.msg }}
        </div>

        <!-- Modal footer -->
        <div class="modal-footer justify-content-center">
          <button class="btn btn-outline bold"
                  data-dismiss="modal"
                  @click="closeDialog">關閉視窗</button>
        </div>

      </div>
    </div>
  </modal>
</template>
<script>
export default {
  name: 'JdyDialog',
  data() {
    return {
      params: {}
    }
  },
  methods: {
    beforeOpened(event) {
      this.params = event.params || {}
      this.$emit('before-opened', event)
    },
    beforeClosed(event) {
      this.params = {}
      this.$emit('before-closed', event)
    },
    closeDialog() {
      this.$modal.hide('jdy-dialog')
    }
  }
}
</script>

<style>
.transparent-bg {
  background-color: transparent !important;
  box-shadow: none !important;;
}
</style>