import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { formatDateTime } from './filters/datetime'
import { getGaTrackingId } from '@/api/gaTrackingId'

import './directives/commonDirectives'
import './errorHandler'

import VModal from 'vue-js-modal'
import Popover from 'vue-js-popover'
import VueAnalytics from 'vue-analytics'

Vue.use(VModal)
Vue.use(Popover)
Vue.use(VueAnalytics, {
  id: () => getGaTrackingId().then(response => {
    return response.data
  }),
  router
})

Vue.filter('formatDateTime', formatDateTime)
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
