import axios from 'axios'
import qs from "qs"
import store from '@/store'

const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
});

// Add a request interceptor
instance.interceptors.request.use(function (config) {
    const token = store.getters.token
    if (token) {
        config.headers['Authorization'] = token
    }

    config.paramsSerializer = params => qs.stringify(params, { allowDots: true });
    config.validateStatus = () => true
    
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use(function (response) {

    const { status, data } = response
    if (status < 200 || 300 <= status) {
        throw { status, data }
    }
    return response;
}, function (error) {
    return Promise.reject(error);
});

export default instance
