import Vue from "vue";
import Vuex from "vuex";
import member from './modules/member'

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production'

const state = {}

const getters = {
  token: (state) => state.member.token
}

const actions = {
}

const mutations = {}

export default new Vuex.Store({
  modules: {
    member
  },
  strict: debug,
  state,
  getters,
  mutations,
  actions
});
