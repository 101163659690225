import { removeToken, getToken, setToken } from '@/utils/auth'
import { getMember } from '@/api/memberData'
import { SET_TOKEN, RESET_STATE, SET_MEMBER_DATA } from './mutation-types'

function initialState() {
    return {
        token: null,
        memberData: {}
    }
}

// getters
const getters = {
}

// mutations
const mutations = {
    [SET_TOKEN](state, payload) {
        state.token = payload
    },
    [RESET_STATE](state) {
        const s = initialState()
        Object.keys(s).forEach(key => {
            state[key] = s[key]
        })
    },
    [SET_MEMBER_DATA](state, payload) {
        state.memberData = payload
    }
}

// actions
const actions = {
    async getMemberStateByToken({ commit, state }, payload) {
        commit(SET_TOKEN, payload)
        setToken(payload)

        if (state.token) {
            const { data } = await getMember()
            commit(SET_MEMBER_DATA, data)
        }
    },
    resetState({ commit }) {
        commit(RESET_STATE)
        removeToken()
    },
    async restoreMemberState({ commit, state }) {
        if (!state.token) {
            const tokenFromCookies = getToken()
            if (tokenFromCookies) {
                commit(SET_TOKEN, tokenFromCookies)
            }
        }

        if (state.token) {
            const { data } = await getMember()
            commit(SET_MEMBER_DATA, data)
        }
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations
}