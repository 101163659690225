<template>
  <div id="app">
    <!--========== HEADER ==============-->
    <header class="header fixed-top">
      <div class="roofbar d-none d-lg-block">
        <div class="container fixed-1140">
          <div class="roofbar-nav d-flex justify-content-end">
            <a
              class="accesskey nav-link"
              accesskey="U"
              id="accesskey-u"
              href="#accesskey-u"
              title="網頁上方主選單連結區"
              >:::</a
            >
            <router-link class="nav-link" title="回首頁" :to="{ name: 'Home' }">
              回首頁
            </router-link>
            <router-link
              :to="{ name: 'Sitemap' }"
              title="網站導覽"
              class="nav-link"
            >
              網站導覽
            </router-link>
          </div>
        </div>
      </div>
      <nav class="navbar navbar-expand-lg navWrap">
        <div class="container fixed-1140">
          <h1 class="navbar-brand" @click="closeNavbar">
            <router-link
              class="nav-link"
              title="司法院資料開放平台"
              :to="{ name: 'Home' }"
            >
              <img
                src="/images/logohead.svg"
                class="img-fluid brandlogo"
                on-image-load
                alt="司法院LOGO"
              />
            </router-link>
          </h1>
          <span
            data-toggle="collapse"
            data-target="#collapsibleNavbar"
            id="mmenuButton"
            onclick="switchNav(event)"
            aria-label="功能列"
            class="navbar-toggler collapsed"
            ><span class="hamicon"></span
          ></span>
          <div
            class="collapse navbar-collapse justify-content-end"
            id="collapsibleNavbar"
          >
            <div class="navbar-nav">
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="javascript:;"
                  data-toggle="dropdown"
                  title="網站說明"
                  >網站說明</a
                >
                <div class="dropdown-menu" @click="closeNavbar">
                  <router-link
                    :to="{ name: 'Origin' }"
                    title="平台介紹"
                    class="dropdown-item"
                    >平台介紹
                  </router-link>

                  <router-link
                    to="/privacy#"
                    title="隱私權"
                    class="dropdown-item"
                    >隱私權
                  </router-link>

                  <router-link
                    to="/sitemap#"
                    title="網站地圖"
                    class="dropdown-item"
                    >網站地圖
                  </router-link>

                  <router-link
                    :to="{ name: 'LicenseTerms' }"
                    title="使用規範"
                    class="dropdown-item"
                    >使用規範
                  </router-link>

                  <router-link
                    :to="{ name: 'SecurityPolicy' }"
                    title="安全政策"
                    class="dropdown-item"
                    >安全政策
                  </router-link>
                </div>
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="javascript:;"
                  data-toggle="dropdown"
                  title="資料目錄"
                  >資料目錄</a
                >
                <div class="dropdown-menu" @click="closeNavbar">
                  <router-link
                    :to="{ name: 'Dataset' }"
                    class="dropdown-item"
                    title="資料查詢"
                  >
                    資料查詢
                  </router-link>
                </div>
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="javascript:;"
                  data-toggle="dropdown"
                  title="資料應用"
                  @click="scrollToHere(100)"
                  >資料應用</a
                >
                <div class="dropdown-menu" @click="closeNavbar">
                  <router-link
                    :to="{ name: 'ApplicationDisplay' }"
                    class="dropdown-item"
                    title="應用展示"
                  >
                    應用展示
                  </router-link>

                  <router-link
                    :to="{ name: 'DevelopmentGuide' }"
                    class="dropdown-item"
                    title="開發指引"
                  >
                    開發指引
                  </router-link>
                </div>
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="javascript:;"
                  data-toggle="dropdown"
                  title="平臺消息"
                  @click="scrollToHere(159)"
                  >平臺消息</a
                >
                <div class="dropdown-menu" @click="closeNavbar">
                  <router-link
                    class="dropdown-item"
                    title="最新消息"
                    :to="{ name: 'News' }"
                  >
                    最新消息
                  </router-link>

                  <router-link
                    class="dropdown-item"
                    title="相關網站"
                    :to="{ name: 'Links' }"
                  >
                    相關網站
                  </router-link>

                  <router-link
                    class="dropdown-item"
                    title="常見問答"
                    :to="{ name: 'QA' }"
                  >
                    常見問答
                  </router-link>
                </div>
              </li>

              <li class="nav-item" @click="closeNavbar" v-if="!memberData.name">
                <router-link
                  :to="{ name: 'MemberLogin' }"
                  class="nav-link account"
                  title="會員登入"
                >
                  <span class="tagTitle mbr-icon">會員登入</span>
                </router-link>
              </li>
              <!-- 登入後 -->
              <li class="nav-item dropdown" v-else>
                <a
                  class="nav-link account dropdown-toggle"
                  href="!#"
                  id="navbardrop"
                  data-toggle="dropdown"
                  @click="scrollToHere(218)"
                  ><span class="tagTitle mbr-icon"
                    >Hi, {{ memberData.name }}</span
                  ></a
                >
                <div class="dropdown-menu">
                  <div @click="closeNavbar">
                    <router-link
                      class="dropdown-item"
                      :to="{ name: 'MemberEdit' }"
                      >會員修改</router-link
                    >
                  </div>
                  <div @click="closeNavbar">
                    <router-link
                      class="dropdown-item"
                      :to="{ name: 'MemberPasswordChange' }"
                      >密碼變更</router-link
                    >
                  </div>
                  <a class="dropdown-item" href="javascript:;" @click="logout">會員登出</a>
                </div>
              </li>
              <li
                class="nav-item dropdown d-block d-sm-none"
                style="height:10rem"
                @click="closeNavbar"
              ></li>
            </div>
          </div>
        </div>
      </nav>
    </header>
    <!--========== END Header ==========-->
    <div id="overlayMask" onclick="switchNav(event)"></div>
    <!--========== Loading =============-->
    <div class="overlayMask open">
      <div class="loader">
        <!-- 如果要顯示無進度條的豆豆，把這段取消註解 -->
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <!-- 如果要顯示無進度條的豆豆，把這段取消註解 -->
        <!-- 如果要顯示有進度條的圖片，把這段取消註解 -->
        <!--
                <p class="ft-white ft12 text-center"><span id="loadingText">0</span> %</p>
                <div class="loading">
                    <div class="loading_mask" id="loadingPic" style="width:100%; height: 100%;">
                        <img on-image-load src="/images/loading.png" class="img-responsive">
                    </div>
                    <img on-image-load src="/images/loading_mask.png" class="img-responsive">
                </div>
            -->
        <!-- 如果要顯示有進度條的圖片，把這段取消註解 -->
      </div>
    </div>
    <!--========== END Loading =========-->

    <!--========== Content =============-->
    <div canvas="container">
      <keep-alive>
        <router-view />
      </keep-alive>

      <!--========== Footer ==========-->
      <footer class="footer">
        <!-- 聯絡資訊 -->
        <div class="container-fluid contactInfo">
          <div class="row fixed-1140">
            <div class="col-12 col-lg-4 offset-lg-4 text-center">
              <p>
                <img
                  alt="司法院資料開放平台Logo"
                  src="/images/logofoot@2x.png"
                  class="brand"
                />
              </p>

              <p class="phoneNbr">02-23618577</p>
              <p class="small mb-0">
                (時間：上午9:00~下午4:00)<br />司法院全球資訊網
                10048臺北市中正區重慶南路一段 124 號
              </p>
            </div>
          </div>
        </div>
        <!-- 聯絡資訊 End -->
        <div class="container-fluid">
          <div class="row justify-content-center contactBtnBlk">
            <div class="col-auto">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.judicial.gov.tw/tw/sp-boma-mailform-1.html"
                class="btn btn-base extline"
                >聯絡我們</a
              >
            </div>
          </div>
          <div class="footerInfo fixed-1140 row">
            <div class="col-12 col-lg-8 offset-lg-2 itemBlk">
              <a
                class="accesskey"
                accesskey="B"
                id="accesskey-b"
                href="#accesskey-b"
                title="下方頁尾選單連結區"
                >:::</a
              >

              <div class="row footerNav">
                <div class="col-12 col-md-3">
                  <p class="navTitle">網站說明</p>
                  <ul class="row list-unstyled">
                    <li class="col-auto col-md-12">
                      <router-link
                        :to="{ name: 'Origin' }"
                        title="平台介紹"
                        class="item"
                        >平台介紹
                      </router-link>
                    </li>
                    <li class="col-auto col-md-12">
                      <router-link
                        :to="{ name: 'Sitemap' }"
                        title="網站地圖"
                        class="item"
                        >網站地圖
                      </router-link>
                    </li>
                    <li class="col-auto col-md-12">
                      <router-link
                        :to="{ name: 'Privacy' }"
                        title="隱私權"
                        class="item"
                        >隱私權
                      </router-link>
                    </li>
                    <li class="col-auto col-md-12">
                      <router-link
                        :to="{ name: 'LicenseTerms' }"
                        title="使用規範"
                        class="item"
                        >使用規範
                      </router-link>
                    </li>
                    <li class="col-auto col-md-12">
                      <router-link
                        :to="{ name: 'SecurityPolicy' }"
                        title="安全政策"
                        class="item"
                        >安全政策
                      </router-link>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-md-3">
                  <p class="navTitle">資料目錄</p>
                  <ul class="row list-unstyled">
                    <li class="col-auto col-md-12">
                      <router-link
                        :to="{ name: 'Dataset' }"
                        title="資料查詢"
                        class="item"
                      >
                        資料查詢
                      </router-link>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-md-3">
                  <p class="navTitle">資料應用</p>
                  <ul class="row list-unstyled">
                    <li class="col-auto col-md-12">
                      <router-link
                        class="item"
                        title="應用展示"
                        :to="{
                          name: 'ApplicationDisplay',
                        }"
                      >
                        應用展示
                      </router-link>
                    </li>
                    <li class="col-auto col-md-12">
                      <router-link
                        class="item"
                        title="開發指引"
                        :to="{ name: 'DevelopmentGuide' }"
                      >
                        開發指引
                      </router-link>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-md-3">
                  <p class="navTitle">平臺消息</p>
                  <ul class="row list-unstyled">
                    <li class="col-auto col-md-12">
                      <router-link
                        class="item"
                        title="最新消息"
                        :to="{ name: 'News' }"
                      >
                        最新消息
                      </router-link>
                    </li>
                    <li class="col-auto col-md-12">
                      <router-link
                        class="item"
                        title="相關網站"
                        :to="{ name: 'Links' }"
                      >
                        相關網站
                      </router-link>
                    </li>
                    <li class="col-auto col-md-12">
                      <router-link
                        class="item"
                        title="常見問答"
                        :to="{ name: 'QA' }"
                      >
                        常見問答
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <!--========== END Footer ======-->
    </div>
    <!--========== END Content =========-->

    <!--========== Modal ==========-->
    <JdyDialog></JdyDialog>
    <!--========== END Modal ==========-->

    <!--========== Scroll Top ==========-->
    <a
      href="javascript:void(0);"
      class="js-back-to-top back-to-top"
      aria-label="最頂端"
      ><span class="icon-arrow_upward"></span
    ></a>
    <!--========== END Scroll Top ======-->
  </div>
</template>

<script>
import JdyDialog from './components/JdyDialog';
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('member');
export default {
  components: { JdyDialog },
  computed: {
    ...mapState(['memberData']),
  },
  methods: {
    ...mapActions(['restoreMemberState', 'resetState']),
    logout() {
      this.resetState();
      this.closeNavbar();
      this.$router.push({ name: 'Home' });
    },
    closeNavbar() {
      var elm = document.getElementById('mmenuButton');
      if (!elm.classList.contains('collapsed')) {
        elm.dispatchEvent(
          new CustomEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
          })
        );
      }
    },

    scrollToHere(scrollTopValue) {
      var container = this.$el.querySelector('#collapsibleNavbar');
      container.scrollTop = scrollTopValue;
    },
  },
  async created() {
    await this.restoreMemberState();
    this.closeNavbar();
  },
  mounted() {
    const appScripts = ['/scripts/loadingProgress.js', '/scripts/main.js'];
    appScripts.forEach((src) => {
      window.console.log(`${src} is loading...`);
      let newScript = document.createElement('script');
      newScript.setAttribute('src', src);
      document.head.appendChild(newScript);
    });
  },
};
</script>

<style>
.jydata-bg1920-01 {
  background: url('../public/images/JYdata-bg1920-01.jpg') no-repeat center top -170px;
  background-attachment: fixed;
}
</style>
