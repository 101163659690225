import Vue from "vue";
import VueRouter from "vue-router";
import qs from "qs"

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/"
  },
  {
    path: "/",
    name: "Home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Home.vue")
  },
  {
    path: "/dataset",
    name: "Dataset",
    component: () =>
      import(/* webpackChunkName: "dataset" */ "../views/Dataset.vue")
  },
  {
    path: "/dataset/detail",
    name: "DatasetDetail",
    component: () =>
      import(/* webpackChunkName: "dataset-detail" */ "../views/DatasetDetail.vue")
  },
  {
    path: "/member/login",
    name: "MemberLogin",
    component: () =>
      import(/* webpackChunkName: "member-login" */ "../views/MemberLogin.vue")
  },
  {
    path: "/member/create",
    name: "MemberCreate",
    component: () =>
      import(/* webpackChunkName: "member-create" */ "../views/MemberCreate.vue")
  },
  {
    path: "/member/edit",
    name: "MemberEdit",
    component: () =>
      import(/* webpackChunkName: "member-edit" */ "../views/MemberEdit.vue")
  },
  {
    path: "/member/PasswordChange",
    name: "MemberPasswordChange",
    component: () =>
      import(/* webpackChunkName: "member-password-change" */ "../views/MemberPasswordChange.vue")
  },
  {
    path: "/member/forget",
    name: "MemberForget",
    component: () =>
      import(/* webpackChunkName: "member-forget" */ "../views/MemberForget.vue")
  },
  {
    path: "/member/MemberPasswordReset",
    name: "MemberPasswordReset",
    component: () =>
      import(/* webpackChunkName: "member-password-reset" */ "../views/MemberPasswordReset.vue")
  },
  {
    path: "/member/MemberVerify",
    name: "MemberVerify",
    component: () =>
      import(/* webpackChunkName: "member-verify" */ "../views/MemberVerify.vue")
  },
  {
    path: "/news",
    name: "News",
    component: () =>
      import(/* webpackChunkName: "news" */ "../views/News.vue")
  },
  {
    path: "/news/detail",
    name: "NewsDetail",
    component: () =>
      import(/* webpackChunkName: "news-detail" */ "../views/NewsDetail.vue")
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () =>
      import(/* webpackChunkName: "privacy" */ "../views/Privacy.vue")
  },
  {
    path: "/sitemap",
    name: "Sitemap",
    component: () =>
      import(/* webpackChunkName: "sitemap" */ "../views/Sitemap.vue")
  },
  {
    path: "/ApplicationDisplay",
    name: "ApplicationDisplay",
    component: () =>
      import(/* webpackChunkName: "application-display" */ "../views/ApplicationDisplay.vue")
  },
  {
    path: "/DevelopmentGuide",
    name: "DevelopmentGuide",
    component: () =>
      import(/* webpackChunkName: "development-guide" */ "../views/DevelopmentGuide.vue")
  },
  {
    path: "/LicenseTerms",
    name: "LicenseTerms",
    component: () =>
      import(/* webpackChunkName: "license-terms" */ "../views/LicenseTerms.vue")
  },
  {
    path: "/Links",
    name: "Links",
    component: () =>
      import(/* webpackChunkName: "links" */ "../views/Links.vue")
  },
  {
    path: "/Origin",
    name: "Origin",
    component: () =>
      import(/* webpackChunkName: "origin" */ "../views/Origin.vue")
  },
  {
    path: "/QA",
    name: "QA",
    component: () =>
      import(/* webpackChunkName: "qa" */ "../views/QA.vue")
  },
  {
    path: "/SecurityPolicy",
    name: "SecurityPolicy",
    component: () =>
      import(/* webpackChunkName: "security-policy" */ "../views/SecurityPolicy.vue")
  }

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  // set custom query resolver
  parseQuery(query) {
    return qs.parse(query, { allowDots: true });
  },
  stringifyQuery(query) {
    var result = qs.stringify(query, { allowDots: true })
    return result ? ('?' + result) : '';
  }
});

export default router;
