import Vue from "vue";
import store from "./store";

Vue.config.errorHandler = function (err, vm) {
    if (err?.status === 401) {
        store.dispatch('member/resetState')
    }

    const msg = err?.data?.message
    if (msg) {
        vm.$modal.show('jdy-dialog', { msg })
    }
}