import request from '@/utils/request'

export function getMember(params) {
    return request.get(`/api/MemberData`, {
        params: params
    })
}

export function updateMember(data, params) {
    return request.put(`/api/MemberData`, data, {
        params: params
    })
}

export function changePassword(data, params) {
    return request.put(`/api/MemberData/change-password`, data, {
        params: params
    })
}

export function createPasswordForgetting(data, params) {
    return request.post(`/api/MemberData/password-forgetting`, data, {
        params: params
    })
}

export function resetPassword(data, params) {
    return request.post(`/api/MemberData/reset-password`, data, {
        params: params
    })
}